
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { EmpreendimentoService } from "@/core/services/cadastros";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
 
@Component
export default class CfgEmpreendimento extends Vue {
  filter: {
    empreendimentoId:[];
    empresaId:[];
    isBaixaAutomatica:string;
    dataVencimentoInicial: string; 
    dataVencimentoFinal:string;
    dataPagamentoInicial:string; 
    dataPagamentoFinal:string;
  } = {
    empreendimentoId:[],
    empresaId:[],
    isBaixaAutomatica:'',
    dataVencimentoInicial:'',
    dataVencimentoFinal: '',
    dataPagamentoInicial: '',
    dataPagamentoFinal: ''
  };

  SimNao = [
    {id:1, nome:"Sim", condicao:true},
    {id:2, nome:"Não", condicao:false},
  ]

  valid = true; 
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  empreendimentos:any = [];
  listaGrupos = [];
  listaUnidades = [];
  empresas:any = [];

  get selecionarTodosEmpreendimentos(){
    return this.filter.empreendimentoId.length === this.empreendimentos.length
  }

  get SelecionarAlgunsEmpreendimentos(){
    return this.filter.empreendimentoId.length > 0 && !this.selecionarTodosEmpreendimentos
  }

  get iconEmpreendimento(){
    if (this.selecionarTodosEmpreendimentos) return 'mdi-close-box'
    if (this.SelecionarAlgunsEmpreendimentos) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  get textEmpreendimento(){
    if (this.selecionarTodosEmpreendimentos) return 'Desmarcar'
    if (this.SelecionarAlgunsEmpreendimentos) return 'Selecionando...'
    return 'Selecionar todos'
  }

  get iconEmpresa(){
    if (this.selecionarTodosEmpresas) return 'mdi-close-box'
    if (this.SelecionarAlgunsEmpresas) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  get textEmpresa(){
    if (this.selecionarTodosEmpresas) return 'Desmarcar'
    if (this.SelecionarAlgunsEmpresas) return 'Selecionando...'
    return 'Selecionar todos'
  } 

  SelecionarEmpreendimento() {
    this.$nextTick(() => {
        if (this.selecionarTodosEmpreendimentos) {
        this.filter.empreendimentoId = []
        } else {
          this.filter.empreendimentoId = this.empreendimentos.slice().map((x:any)=>x.id)
        }
    })
  }

  SelecionarEmpresa(){
    this.$nextTick(() => {
      if (this.selecionarTodosEmpresas) {
        this.filter.empresaId = []
      } else {
        this.filter.empresaId = this.empresas.slice().map((x:any)=>x.id)
      }
    })
  }

  get selecionarTodosEmpresas(){
    return this.filter.empresaId.length === this.empresas.length
  }

  get SelecionarAlgunsEmpresas(){
    return this.filter.empresaId.length > 0 && !this.selecionarTodosEmpresas
  }

  mounted() {
    new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      (res) => {
        this.empreendimentos = res.data.items
      }, 
    );

    new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome,nomeFantasia', '').then(
      (res) => {
        this.empresas = res.data.items
      }, 
    );
  }   

  Visualizar() {
    if((!this.filter.dataVencimentoInicial || !this.filter.dataVencimentoFinal) && (!this.filter.dataPagamentoInicial || !this.filter.dataPagamentoFinal)){
        this.$swal("Aviso", "Obrigatório preencher início e fim de pelo menos uma das datas.", "warning");
        return true;
    }      
    let routeLink = this.$router.resolve({name: 'RelTaxaAdm',
      query:{
        empreendimentoId: this.filter.empreendimentoId ? this.filter.empreendimentoId.toString() : "",
        empresaId: this.filter.empresaId ? this.filter.empresaId.toString() : '',
        isBaixaAutomatica: this.filter.isBaixaAutomatica != null ? this.filter.isBaixaAutomatica : "", 
        dataVencimentoInicial: this.filter.dataVencimentoInicial ? this.filter.dataVencimentoInicial.toString() : "",
        dataVencimentoFinal: this.filter.dataVencimentoFinal ? this.filter.dataVencimentoFinal.toString() : "",
        dataPagamentoInicial: this.filter.dataPagamentoInicial ? this.filter.dataPagamentoInicial.toString() : "",
        dataPagamentoFinal: this.filter.dataPagamentoFinal ? this.filter.dataPagamentoFinal.toString() : "",
      }
    });

    window.open(routeLink.href, '_blank');
  }
}
